.game-wrapper {
    text-align: center;
    display: flex;
    max-width: 480px;
    background: #2054c4;
    margin: auto;
    color: white;
    min-height: 100vh;
    width: 100%;
    align-items: center;
    justify-content: center;
}