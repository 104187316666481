.drawer-box{
    z-index: 99999;
    .MuiPaper-root{
       background-color: transparent !important;
       --Paper-shadow: none !important;
       max-width: 480px;
       margin: auto;
       .header{
            padding: 20px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            .name-head{
                font-family: "Bangers", system-ui !important;
                font-size: 24px;
                line-height: 25.54px;
                letter-spacing: 4%;
                color: #2054C4;
            }
        }
        .main-box{
            background-color: #CDE5FF;
            border-radius: 18.53px;
            margin: 0 10px;
            padding: 18px;
            display: flex;
            flex-wrap: wrap; /* Allow items to wrap to the next line */
            gap: 13px; 
        }
        .footer-container {
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 8px;
        }
        .footer-wrap {
            position: relative;
            .totalCardsWrap {
                position: absolute;
                padding: 4px;
                border-radius: 50px;
                background: #111D61;
                color: #FFE53B;
                font-size: 10px;
                font-weight: 457;
                line-height: 8.7px;
                letter-spacing: -0.04em;
                text-align: center;
                bottom: 0;
                left: -4px;
                z-index: 2;
            }

            .cardsCount {
                position: absolute;
                left: -4px;
                z-index: 1;
                top: 0;
            }
        }
        .points {
            border: 1px solid #4A67A6;
            background: linear-gradient(180deg, #1E4391 0%, #0B204F 100%);
            border-radius: 12px;
            padding: 2px 16px;
            font-size: 10px;
            font-weight: 457;
            line-height: 11.93px;
            letter-spacing: -0.04em;
            text-align: center;
            color: #ffff
        }

        .points-val {
            font-size: 16px;
            font-weight: 790;
            line-height: 19.09px;
            letter-spacing: -0.04em;
            text-align: center;
            position: relative;

            .passScoreBall {
                position: absolute;
                left: -5px;
                top: 4px;
            }
        }
    }
}