.table-container {
  background-color: transparent; // Removing background to be outside of the box
  padding: 0;
  width: 100%;
  max-width: 600px;
  margin: 0 auto;
  color: white;

  .header {
    margin-bottom: 10px;
    text-align: left;
    padding: 0 24px;
    .MuiTypography-root {
      font-size: 16px;
      font-weight: 400;
      color: rgba(255, 255, 255, 1); // Blue color for headers
    }
    @media screen and (max-width: 350px) {
      .MuiTypography-root {
        font-size: 13.5px;
        font-weight: 400;
        color: rgba(255, 255, 255, 1); // Blue color for headers
      }
    }
  }

  .player-container {
    background-color: #5171D3;
    border-radius: 20px;
    border: 2px solid #6785D9;
    .player-div{
      padding: 0 24px;
      &:last-child {
        .player-row{
          border-bottom: none;
        }
      }
    }
    .first-row{
      border-radius: 20px 20px 0 0;
    }
    .green-bg{
      background: linear-gradient(90deg, rgba(141, 255, 10, 0.8) -0.61%, rgba(24, 119, 0, 0) 100.61%);
      
    }
    .red-bg{
      background: linear-gradient(90deg, rgba(255, 69, 10, 0.8) -0.61%, rgba(121, 26, 5, 0) 100.61%);
    }
    .player-row {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 14px 0 14px;
      border-bottom: 1px solid rgba(255, 255, 255, 0.1);

      

      .player-info {
        display: flex;
        align-items: center;
        position: relative;
        .crown-img{
          width: 40px;
          height: 25px;
          position: absolute;
          top: -22%;
          z-index: 9;
          left: -4px;
        }
        .one-img{
          width: 32px;
          height: 32px;
          position: absolute;
          z-index: 9;
          top: 12%;
          left: 27px;
        }
        .avatar-container {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          position: relative;
          width: 40px;
          height: 40px;
          border: 0.83px solid #123379;
          border-radius: 50%;
          overflow: hidden;

          .avatar {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }

          .player-name {
            position: absolute;
            bottom: -2px;
            color: white;
            font-size: 10px;
            padding: 1px 2px;
            width: 100%;
            white-space: nowrap;
            text-align: center;
            background-color: #123379;
            font-family: "Saira Semi Condensed", sans-serif;
            font-weight: 400;
          }
        }
      
      }

      .points-earned, .balls-earned {
        display: flex;
        align-items: center;
        justify-content: flex-start;

        .points, .balls {
          font-size: 18px;
          font-weight: 700;
        }
        

        .ball-icon {
          width: 24px;
          margin-right: 5px;
        }
      }
    }
  }
}