.player-joining {
    padding: 21px 43px 36px;
    background-image: url('../../../src/assets/s-bg.png');
    min-height: 100vh;
    min-height: calc(100vh - 57px);
    height: 100%;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    background-blend-mode: soft-light;
    display: flex;
    flex-direction: column;
    width: 100%;
    // justify-content: space-between;
    @media screen and (max-width: 320px) {
        padding: 21px 25px 36px;
      }
    .player-count {
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        .player-box {
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: #5079D1;
            padding: 8px 20px;
            width: fit-content;
            border-radius: 20px;
            position: relative;
            z-index: 9;
        }

        .border {
            position: absolute;
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }

    .user-list {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        /* Two columns */
        gap: 14px;
        /* Space between items */
        margin-bottom: 50px;
        margin-top: 50px;
        .user-view {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;

            .user-img {
                border-radius: 50%;
                background-color: #5079D1;
                width: 125px;
                height: 125px;
                border: 1px solid #5683e6;

                .MuiAvatar-root {
                    width: 100%;
                    height: 100%;
                    border-radius: 50%;
                }
            }

            .user-text {
                font-weight: 400;
                font-size: 12px;
                color: #fff;
                margin-top: 6px;
            }
        }
    }

    .score-box {
        background-color: #5079D1;
        border: 1px solid #5683e6;
        border-radius: 15px;
        padding-bottom: 17px;
        width: 70%;
        margin: auto;
        margin-bottom: 20px;
        .win-amount{
            padding: 8px 17px;
            background-color: #5F87DE;
            width: fit-content;
            border-radius: 0 0 20px 20px;
            margin: auto;
            margin-bottom: 8px;
            display: flex;
            align-items: center;
            justify-content: center;
        }
        .amount{
            font-weight: 700;
            font-size: 22px;
            color: #fff;
        }
    }

    .loading-box {
        .loading-text {
            font-size: 14px;
            font-weight: 400;
        }
    }
}