.loading-page-wrap {
    display: flex;
    // min-height: 93vh;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
    background-image: url('../../../src/assets/images/landing-load.png');
    min-height: 100vh;
    height: 100%;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    width: 100%;
    .loader-wrap {
        width: 80%;
        margin: 60px 0;
    }
}