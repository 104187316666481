.joinMatch-page-wrap {
    padding: 20px 0px;
    display: flex;
    flex-direction: column;
    row-gap: 2.5vh;
    padding-bottom: 0;
    width: 100%;

    .header-wrap {
        padding: 0px 15px;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .heading {
            font-size: 18px;
            font-weight: 790;
            line-height: 21.48px;
            letter-spacing: -0.04em;
            text-align: left;
        }

        .avatar-wrap {
            display: flex;
            align-items: center;
            width: fit-content;
            border-radius: 5px 16px 16px 5px;
            background: #FFFFFF26;
            height: 25px;


            .addIcon {
                border-radius: 5px 0px 0px 5px;
                background: #FFFFFF17;
                color: #FFFFFF8F;
                display: flex;
                align-items: center;
            }

            .amount {
                font-size: 14px;
                font-weight: 656;
                line-height: 16.71px;
                letter-spacing: -0.04em;
                text-align: center;
                padding: 0px 8px;
            }
        }
    }

    .customTabs-wrap {
        margin-left: 15px;
    }

    .cards-wrap {
        padding: 0px 15px;
        height: 83vh;
        overflow: auto;
        display: flex;
        flex-direction: column;
        gap: 12px;

        .card-wrap {
            padding: 18px 16px;
            width: auto;
            border-image-source: linear-gradient(180deg, rgba(255, 255, 255, 0.2) 0%, rgba(255, 255, 255, 0.08) 100%);
            width: 100%;
            background: linear-gradient(180deg, rgba(255, 255, 255, 0.2) 0%, rgba(255, 255, 255, 0.08) 100%);
            border-radius: 20px;
            display: flex;
            justify-content: space-between;
            width: auto;
            border: 1px solid #638ADF;

            .left-content-wrap {
                display: flex;
                flex-direction: column;
                justify-content: space-around;

                .wins-wrap {
                    display: flex;
                    align-items: center;
                    margin-bottom: 7px;
                    position: relative;

                    .title {
                        font-size: 12px;
                        font-weight: 457;
                        line-height: 14.32px;
                        letter-spacing: -0.04em;
                        text-align: left;

                    }

                    .price {
                        font-size: 26px;
                        font-weight: 790;
                        line-height: 31.03px;
                        letter-spacing: -0.04em;
                        text-align: left;

                    }
                }

                .wins-wrap::before {
                    content: '';
                    position: absolute;
                    width: 260px;
                    height: 2px;
                    background: linear-gradient(90deg, rgba(255, 255, 255, 0.11) 60%, rgba(255, 255, 255, 0) 100%);
                    bottom: -8px;
                    left: -16px;
                }

                .second-win {
                    display: flex;
                    align-items: center;
                    margin-left: 10px;
                    margin-top: 10px;
                    gap: 15px;


                    .price {
                        font-size: 18px;
                        font-weight: 656;
                        line-height: 21.48px;
                        letter-spacing: -0.04em;
                        text-align: left;

                    }
                }
            }

            .right-content-wrap {
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                gap: 8px;
                .players-wrap {
                    opacity: 0.8;
                    background: linear-gradient(0deg, rgba(255, 255, 255, 0.2) 0%, rgba(255, 255, 255, 0) 100%);
                    margin-top: -20px;
                    display: flex;
                    align-items: center;
                    border-radius: 0px 0px 20px 20px;
                    justify-content: center;
                    padding: 7px 15px;
                    gap: 6px;
                    font-size: 12px;
                    font-weight: 457;
                    line-height: 14.32px;
                    letter-spacing: -0.04em;
                    text-align: left;

                }

                .joinMatch {
                    font-size: 14px;
                    font-weight: 556;
                    line-height: 16.71px;
                    letter-spacing: -0.04em;
                    text-align: center;

                }
                .button-wrap{
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;
                    gap: 8px;

                }
            }
        }
    }
}