.customTabs-wrap {
    .MuiTab-root {
        border: 1px solid #638ADF;
        background: #FFFFFF1A;
        border-radius: 60px;
        color: #FFFFFF;
        opacity: 60%;
        font-size: 14px;
        font-weight: 457;
        line-height: 16.71px;
        letter-spacing: -0.04em;
        text-align: left;
        text-transform: unset;
    }
    .MuiTabs-flexContainer{
        column-gap: 8px;
    }
    .Mui-selected{
        box-shadow: 1.02px 2.04px 1.02px 0px #FFFFFF66 inset;
        background: #FFFFFF;
        opacity: 100%;
        color: #2D4A89 !important;
        font-size: 14px;
        font-weight: 656;
        line-height: 16.71px;
        letter-spacing: -0.04em;
        text-align: center;
    }
    .MuiTabs-indicator{
        display: none;
    }
}