.final-score-board{
    padding: 33px 22px;
    background-image: url('../../../src/assets/final-bg.png');
    min-height: 93vh;
    height: 100%;
    background-repeat: no-repeat;
    background-position: unset;
    background-size: cover;
    background-blend-mode: soft-light;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    .heading{
        font-family: "Bangers", system-ui !important;
        font-size: 40px;
        line-height: 42.56px;
        font-family: Bangers;
        letter-spacing: 0.04em;
    }
    .table-block{
        margin: 40px 0 80px;
    }
    .buttons-div-wrap{
        display: flex;
        flex-direction: column;
        gap: 20px;
    }
    .button-div{
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    .final-div{
        margin-top: 10px;
        .low-text{
            display: flex;
            align-items: center;
            justify-content: center;
            font-family: "Saira Semi Condensed", sans-serif;
            font-weight: 500;
            font-size: 16px;
            color: #EEFF34;
            margin-bottom: 11px;
        }
    }
}