.score-board{
    padding: 33px 22px;
    background-image: url('../../../src/assets/s-bg.png');
    min-height: 93vh;
    height: 100%;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    background-blend-mode: soft-light;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    .heading{
        font-family: "Bangers", system-ui !important;
        font-size: 40px;
        line-height: 42.56px;
        font-family: Bangers;
        letter-spacing: 0.04em;
    }
    .table-block{
        margin: 40px 0 80px;
    }
    .loading-box{
        .loading-text{
            font-size: 14px;
            font-weight: 400;
        }
    }
}