@import '../../styles/colors';

.login-wrap{
    width: 100%;
    min-height: 100vh;
    .loginImage{
        width: 100%;
    }
    .box-content{
        padding: 0px 10px 20px;
        .welcome-heading{
            font-size: 24px;
            font-weight: 800;
            letter-spacing: -0.04em;
            text-align: center;
        }
        .login-subheading{
            margin-top: 8px;
            font-size: 14px;
            font-weight: 400;
            letter-spacing: -0.04em;
            text-align: center;
            margin-bottom: 30px;
        }
    }
}