.linear-loader-wrap {
    overflow: hidden;
    border-radius: 32px;
    .MuiLinearProgress-root {
        box-shadow: 0px 4px 6.5px 0px #00000075 inset;
        height: 12px;
        background: #00000075;
    }
    .MuiLinearProgress-bar {
        background: #ffffffeb;
        border-radius: 32px;
        box-shadow: 0px 2.04px 2.04px 0px #00000040;
        box-shadow: 1.02px 2.04px 1.02px 0px #FFFFFF66 inset;
    }
}