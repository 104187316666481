/* AdminDashboard.css */
html,
body {
    margin: 0;
    padding: 0;
    height: 100%;
    width: 100%;
    font-family: 'Arial', sans-serif;
}

.admin-dashboard {
    display: flex;
    height: 100vh;
    width: 100vw;
}

.admin-dashboard .sidebar {
    background-color: #2c3e50;
    color: white;
    width: 250px;
    padding: 20px;
    display: flex;
    flex-direction: column;
}

.admin-dashboard .sidebar h2 {
    text-align: center;
    margin-bottom: 30px;
    color: white;
}

.admin-dashboard .sidebar ul {
    list-style-type: none;
    padding: 0;
}

.admin-dashboard .sidebar ul li {
    padding: 15px;
    cursor: pointer;
    border-radius: 8px;
    text-align: left;
    transition: background-color 0.3s ease;
    margin-bottom: 10px;
}

.admin-dashboard .sidebar ul li:hover,
.admin-dashboard .sidebar ul li.active {
    background-color: #34495e;
}

.admin-dashboard .main-content {
    flex-grow: 1;
    padding: 40px;
    background-color: #ecf0f1;
}

.admin-dashboard .tab-content {
    background-color: white;
    padding: 30px;
    border-radius: 8px;
    box-shadow: 0 10px 25px rgba(0, 0, 0, 0.1);
    margin: 0 auto;
    max-width: 600px;
}

.admin-dashboard .tab-content h1 {
    color: #34495e;
    font-size: 28px;
    font-weight: bold;
    padding-bottom: 20px;
}

.admin-dashboard .tab-content .title {
    color: #34495e;
    font-size: 28px;
    font-weight: bold;
    padding-bottom: 20px;
}

.admin-dashboard .config-info {
    text-align: left;
}

.admin-dashboard .config-info p {
    font-size: 18px;
    margin-bottom: 10px;
    color: #34495e;
}

.admin-dashboard .edit-btn,
.admin-dashboard .save-btn {
    padding: 10px 20px;
    background-color: #3498db;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    margin-top: 20px;
}

.admin-dashboard .edit-btn:hover,
.admin-dashboard .save-btn:hover {
    background-color: #2980b9;
}

.admin-dashboard .config-form {
    display: flex;
    flex-direction: column;
    max-height: 70vh;
    overflow-y: scroll;
}

.admin-dashboard .config-form label {
    margin-bottom: 15px;
    font-size: 16px;
    text-align: left;
}

.admin-dashboard .config-form input {
    padding: 10px;
    font-size: 14px;
    margin-top: 5px;
    border-radius: 5px;
    border: 1px solid #ccc;
    width: 100%;
}

.admin-dashboard .upload-section {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.admin-dashboard .upload-section input[type="file"] {
    margin-bottom: 15px;
}

.admin-dashboard .upload-section p {
    color: #34495e;
    font-size: 16px;
    text-align: left;
}

.admin-dashboard .upload-section .error-message {
    color: red;
}

.admin-dashboard .upload-btn {
    padding: 10px 20px;
    background-color: #3498db;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

.admin-dashboard .upload-btn:hover {
    background-color: #2980b9;
}

.token-wrap {
    color: #34495e;
    word-wrap: break-word;
}

.remove-input-wrap {
    display: flex;
}

.remove-input-wrap .remove-btn {
    border: none;
    background-color: transparent;
}

.add-input-wrap {
    display: flex;
}

.add-input-wrap .add-btn {
    border: 1px solid #2c3e50;
    background-color: transparent;
    padding: 5px 20px;
    margin: 10px;
}

.add-input-wrap .add-btn:hover {
    background-color: black;
    color: white;
}