/* AdminLogin.css */
/* .App {
    text-align: center;
    display: flex;
    max-width: 100%;
    background: #2054c4;
    margin: auto;
    color: white;
    min-height: 100vh;
    width: 100%;
    align-items: center;
    justify-content: center;
} */

.admin-login-container {
    width: 100%;
    max-width: 400px;
    margin: 0 auto;
    padding: 40px;
    background-color: white;
    border-radius: 8px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

h2 {
    margin-bottom: 20px;
    color: #333;
    font-family: "Arial", sans-serif;
}

.admin-login-form {
    display: flex;
    flex-direction: column;
    align-items: stretch;
}

.form-group {
    margin-bottom: 20px;
    text-align: left;
}

label {
    display: block;
    margin-bottom: 8px;
    font-weight: bold;
    font-size: 14px;
    color: #333;
}

input {
    width: 100%;
    padding: 12px 15px;
    font-size: 16px;
    border: 1px solid #ccc;
    border-radius: 8px;
    box-sizing: border-box;
    transition:
        border-color 0.3s,
        box-shadow 0.3s;
}

input:focus {
    border-color: #0056b3;
    box-shadow: 0 0 5px rgba(0, 86, 179, 0.5);
    outline: none;
}

.error-input {
    border-color: red;
}

.error-message {
    color: red;
    font-size: 14px;
    margin-top: 5px;
    display: block;
}

.admin-login-button {
    padding: 12px 20px;
    background-color: #0056b3;
    color: white;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    font-size: 16px;
    width: 100%;
    transition: background-color 0.3s;
}

.admin-login-button:hover {
    background-color: #00408f;
}

.admin-login-button:focus {
    outline: none;
}

@media (max-width: 768px) {
    .admin-login-container {
        padding: 20px;
        width: 90%;
    }
}
