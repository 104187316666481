.gameDetail{
    min-height: 90vh;
    width: 90%;
    .heading{
        font-family: "Bangers", system-ui !important;
        font-size: 40px;
        line-height: 42.56px;
        font-family: Bangers;
        letter-spacing: 0.04em;
    }
    .gameDetail-content{
        background-color: #5171D3;
        margin-top: 50px;
        border: 2px solid #6785D9;
        border-radius: 20px;
        padding: 20px;
        .list-tab{
            border-bottom: 1px solid rgba(255, 255, 255, 0.1);
            padding: 15px 0px;

        }

    }

    .url-wrap{
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 5px;
        border: 1px solid #6785D9;
        padding: 1px 7px;
        border-radius: 8px;
        margin-top: 20px;
        background-color: #5171D3;
        padding-right: 0px;
        .url{
            text-wrap: nowrap;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }
        .copy-wrap{
            position: relative;
            .MuiButtonBase-root{
                background: #9e9e9e47;
                border-radius: 3px;
                font-size: 14px;
                color: white;
            }
            .MuiSnackbar-root{
                position: absolute;
                left: -20px;
            }
            .MuiSnackbarContent-root {
                min-width: 125px;
            }
        }
    }

}