.game-screen-wrap {
    background-image: url('../../assets/main-game-bg.png');
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    background-blend-mode: soft-light;
    width: 100%;
    height: calc(100vh - 40px);
    padding: 20px 24px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .header-wrap {
        display: flex;
        justify-content: space-between;
        margin-bottom: 5px;
        .otherUserAvatar-wrap {
            margin-top: 0;
            max-width: 75px;
        }
    }

    .content-wrap {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        row-gap: 2vh;

        .card-wrap {
            position: relative;
            width: 25vw;
            max-width: 200px;

            img {
                width: 100%;
                border-radius: 10px;
            }

            .cardTokenCount-wrap {
                position: absolute;
                left: -55px;
                top: 14vh;
                z-index: 2;
                font-size: 24px;
                font-weight: 790;
                line-height: 24px;
                letter-spacing: -0.08em;
                text-align: center;

                .cardTokenCountImage {
                    position: absolute;
                    left: -10px;
                    top: -40px;
                    z-index: -1;
                    width: 50px;
                }
            }
        }

        .card-number-wrap {
            position: relative;
            width: max-content;
            z-index: 2;
            font-size: 28px;
            font-weight: 790;
            line-height: 33.41px;
            letter-spacing: -0.04em;
            text-align: center;
            margin-left: -46px;
            width: 32px;

            .cardNumberImage {
                position: absolute;
                left: -5px;
                top: -24px;
                z-index: -1;
            }
        }

        .buttons-wrap {
            display: flex;
            gap: 10px;
            align-items: center;
            width: 80%;
            justify-content: space-between;
            margin-top: 3vh;
        }

    }

    .footer-wrap {
        display: flex;
        justify-content: space-between;
        margin-top: 5px;
        .otherUserAvatar-wrap {
            margin-bottom: 0;
        }
        .you-content-wrap{
            max-width: 125px;
        }

    }

    .otherUserAvatar-wrap {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: end;
        gap: 2px;
        margin: auto;

        .self {
            transform: scale(1.5);
            margin-bottom: 1vh;
        }

        .avatar-wrap {
            position: relative;
            display: block;
            border-radius: 100px;
            overflow: hidden;

            .name {
                position: absolute;
                bottom: 0px;
                font-family: Saira SemiCondensed;
                font-size: 10px;
                font-weight: 400;
                line-height: 10px;
                 letter-spacing: -0.04em;
                text-align: center;
                background: #123379;
                left: 0px;
                width: 100%;
            }

            .timer {
                position: absolute;
                z-index: 3;
                top: 3px;
                background: #D4FF00;
                left: 3px;
                padding: 3px 5px;
                border-radius: 100px;
                font-family: "Saira SemiCondensed";
                font-size: 24px;
                font-weight: 600;
                color: #0A2565;
                width: 24px;
            }

            .progressCircularWrap {
                position: absolute;
                width: 40px;
                z-index: 2;
                top: 0px;
                left: 0px;

                .CircularProgressbar .CircularProgressbar-trail {
                    stroke: #D4FF005C;
                }
            }
        }

        .footer-container {
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 8px;
        }

        .points {
            border: 1px solid #4A67A6;
            background: linear-gradient(180deg, #1E4391 0%, #0B204F 100%);
            border-radius: 12px;
            padding: 2px 16px;
            font-size: 10px;
            font-weight: 457;
            line-height: 11.93px;
            letter-spacing: -0.04em;
            text-align: center;

        }

        .points-val {
            font-size: 16px;
            font-weight: 790;
            line-height: 19.09px;
            letter-spacing: -0.04em;
            text-align: center;
            position: relative;

            .passScoreBall {
                position: absolute;
                left: -5px;
                top: 4px;
            }
        }

        .footer-wrap {
            position: relative;


            .totalCardsWrap {
                position: absolute;
                padding: 4px;
                border-radius: 50px;
                background: #111D61;
                color: #FFE53B;
                font-size: 10px;
                font-weight: 457;
                line-height: 8.7px;
                letter-spacing: -0.04em;
                text-align: center;
                bottom: 0;
                left: -4px;
                z-index: 2;
            }

            .cardsCount {
                position: absolute;
                left: -4px;
                z-index: 1;
            }
        }

        .card-wrap {
            display: flex;
            gap: 2px;
            flex-wrap: wrap;
            align-items: center;

            .seq-card-wrap {
                display: flex;
                gap: 2px;
                border: 0.6px solid #588CFF;
                padding: 3px;
                background: #203E7F;
            }

            .card-shadow {
                opacity: 60%;
            }

            .card {
                box-shadow: 0px 0.28px 1.14px 0px #00000040;
                background: linear-gradient(180deg, #FFFFFF 0%, #F0D4FF 100%);
                font-size: 11px;
                font-weight: 790;
                line-height: 13.13px;
                letter-spacing: -0.04em;
                text-align: center;
                color: #0C154B;
                padding: 0 1px;
                width: 12px;
                height: 20px;
                display: flex;
                align-items: center;
                justify-content: center;
                border-radius: 1px;

            }
        }


    }





}


.MuiTooltip-tooltip {
    background: #11f566 !important;
    box-shadow: 1.58px -3.15px 9.3px 0px #19074940 inset !important;
    font-size: 14px !important;
    font-weight: 656 !important;
    line-height: 15.4px !important;
    letter-spacing: -0.04em !important;
    text-align: center !important;
    border-radius: 20px !important;
    padding: 10px 15px !important;
    // font-family: inherit !important;
    color: black !important;
    .lightText{
        color: #216F4F !important;
    }
    .dark{
        color: black !important;

    }
}

.MuiTooltip-arrow {
    color: #11f566 !important;
}